.adminDashboardContent {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 0;
}

.infoBoxes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.infoBoxesLarge {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.infoBox {
    background-color: #212234;
    color: white;
    border-radius: 8px;
    padding: 10px; /* Reduce padding to make the boxes smaller */
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.infoBoxLarge {
    background-color: #212234;
    color: white;
    border-radius: 8px;
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    height: auto; /* Adjust height to fit content */
}

.infoBox:last-child {
    margin-right: 0;
}

.infoBoxLarge:last-child {
    margin-right: 0;
}

.infoIcon {
    font-size: 2em;
    margin-right: 10px;
}

.infoText {
    display: flex;
    align-items: center;
}

.infoText h2 {
    margin: 0;
    font-size: 1.2em;
    margin-right: 10px;
}

.infoText p {
    margin: 0;
    font-size: 1.5em;
}

.infoIconGreen {
    color: #10b981;
}

.infoIconRed {
    color: #ef4444;
}

.infoIconOrange {
    color: #f59e0b;
}

.infoIconBlack {
    color: #000000;
}

.activeUsers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.sliderContainer {
    width: 80%;
    margin: 20px auto;
}
