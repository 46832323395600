.container {
    padding: 20px;
    background-color: #212234;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
    max-width: 1000px;
    margin: 20px auto;
    border: 1px solid #565FFF;
}

.section {
    margin-bottom: 20px;
}

.moduleList, .quickCommandList {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
    border: 1px solid #565FFF;
    border-radius: 4px;
    padding: 10px;
    background-color: #2c2f48;
    scrollbar-width: none; /* Firefox */
}

.moduleList::-webkit-scrollbar, .quickCommandList::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.moduleItem, .quickCommandItem {
    padding: 10px;
    border: 1px solid #565FFF;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3b3f5c;
}

.moduleItem:hover, .quickCommandItem:hover {
    background-color: #4a4f6e;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.textBox, .textArea {
    padding: 10px;
    border: 1px solid #565FFF;
    border-radius: 4px;
    background-color: #2c2f48;
    color: white;
    width: calc(100% - 20px);
}

.searchBar {
    padding: 10px;
    border: 1px solid #565FFF;
    border-radius: 4px;
    background-color: #2c2f48;
    color: white;
    width: calc(100% - 20px);
    margin-bottom: 20px;
}

.button {
    padding: 10px 15px;
    background-color: #565FFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #4343d3;
}
