.usersContent {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tableContainer {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #212234;
    border-radius: 4px;
    background-color: #212234;
    padding: 10px;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.tableContainer::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.searchInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #212234;
    border-radius: 4px;
    background-color: #212234;
    color: white;
}

.searchInput::placeholder {
    color: #a0aec0;
}

.usersTable {
    width: 100%;
    border-collapse: collapse;
}

.usersTable th, .usersTable td {
    padding: 10px;
    border: 1px solid #212234;
    text-align: left;
    cursor: pointer;
}

.usersTable th {
    background-color: #212234;
    color: #fff;
}

.usersTable tr:nth-child(even) {
    background-color: #20202e;
}

.usersTable tr:nth-child(odd) {
    background-color: #2a2a3b;
}

.usersTable td {
    color: #fff;
}

.userPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupContent {
    background: #212234;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 600px; /* Increased width */
    max-width: 90%;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.advancedInfo {
    margin-top: 20px;
}

.advancedInfo h3 {
    margin-bottom: 10px;
}

.closeButton {
    background: #ef4444;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}

.tableHeader {
    color: white;
    text-align: center;
    margin-bottom: 10px;
}
