.registeredPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 90%;
    max-width: 600px;
    margin: auto;
    border-radius: 10px;
    color: white;
    background-color: #212234;
    margin-top: 10%;
}

@media (max-width: 768px) {
    .registeredPage {
        position: relative;
        margin-top: 50%;
    }
}

.registeredPage img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.registeredPage .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.registeredPage .profileName {
    margin-top: 20px;
    text-align: center;
}