.carouselContainer {
    width: 100%;
    overflow: hidden;
    margin: 20px auto;
    position: relative;
    height: 490px; /* Höhe beibehalten */
}

.carouselTrack {
    display: flex;
    position: absolute;
    white-space: nowrap;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.track1 {
    animation: scroll 90s linear infinite, fade 2s ease-in-out;
    top: 0;
    margin-top: 20px;
    animation-delay: -40s;
}

.track2 {
    animation: scroll 90s linear infinite, fade 2s ease-in-out;
    top: 120px;
    margin-top: 20px;
    animation-delay: -10s;
}

.track3 {
    animation: scroll 90s linear infinite, fade 2s ease-in-out;
    top: 240px;
    margin-top: 20px;
    animation-delay: -20s;
}

.track4 {
    animation: scroll 90s linear infinite, fade 2s ease-in-out;
    top: 360px;
    margin-top: 20px;
    animation-delay: -30s;
}

.carouselItem {
    min-width: 180px;
    height: 80px;
    margin-right: 20px;
    padding: 10px 15px;
    background-color: #212234;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 1;
    transition: opacity 1s ease;
}

.carouselItem h4 {
    margin: 0;
    font-size: 15px;
    font-weight: bold;
}

.carouselItem p {
    margin: 5px 0 0;
    font-size: 12px;
}

@keyframes scroll {
    from { transform: translateX(100%); }
    to { transform: translateX(-100%); }
}

@keyframes fade {
    0%, 100% { opacity: 0; }
    10%, 90% { opacity: 1; }
}
