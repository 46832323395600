.heroSection {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px 20px;
    background-color: #212234;
    color: #fff;
    max-width: 80%;
    border-radius: 10px;
    margin: 0 auto; 
}

.heroContent {
    max-width: 800px;
}

.heroTitle {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.heroText {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.discordLink {
    display: inline-block;
    padding: 15px 30px;
    font-size: 1.2em;
    color: #fff;
    background-color: #7289da;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.discordLink:hover {
    background-color: #5a6fb2;
}