.tosContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #fff;
    background-color: #212234;
    border-radius: 5px;
}
  
.tosTitle {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
}
  
.tosSubheading {
    font-size: 1.5em;
    margin-top: 20px;
}
  
.tosInfo {
    margin-bottom: 15px;
}
