.footer {
    text-align: center;
    padding: 20px;
    background-color: #212234;
    color: #fff; /* Ensure text color is white */
    bottom: 10px;
    width: calc(100% - 40px);
    border-radius: 5px;
    margin-top: 80px;
}

.footerLink {
    color: #fff; /* Ensure link color is white */
    text-decoration: none;
    margin: 0 10px;
}

.footerLink:hover {
    text-decoration: underline;
}

.footerLinks {
    margin-top: 10px;
}