.messagesContent {
    padding: 20px;
    background-color: #212234;
    color: white;
    border-radius: 8px;
}

.messagesContent h1 {
    margin-bottom: 20px;
}

.centered {
    text-align: center;
}

.messageBox {
    margin-bottom: 20px;
}

.textarea {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
    border: none;
    resize: none;
    background-color: #2a2a3b;
    color: white;
}

.messageInfoBoxesRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.messageInfoBox {
    background-color: #2a2a3b;
    color: white;
    border-radius: 8px;
    padding: 20px;
    flex: 1;
    margin-right: 10px;
}

.messageInfoBox:last-child {
    margin-right: 0;
}

.messageRadioGroup {
    display: flex;
    flex-direction: column;
}

.messageRadioGroup label {
    margin-bottom: 10px;
}

.messageRadioGroup input {
    margin-right: 10px;
}

.button {
    background-color: #2563eb;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
}

.button:hover {
    background-color: #1d4ed8;
}

.activeMessages {
    margin-top: 20px;
}

.activeMessage {
    background-color: #2a2a3b;
    color: white;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.activeMessageButton {
    background-color: #ef4444;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    align-self: flex-end;
}

.activeMessageButton:hover {
    background-color: #dc2626;
}
