.create-bot-page {
    background-color: #212234;
    max-width: 80%;
    border-radius: 10px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: #fff;
    position: relative;
    overflow: hidden;
    padding: 20px;
}

.form-group {
    margin-bottom: 20px;
}

#botName {
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #565FFF;
    background-color: #fff;
    color: #000;
}

.search-bar {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #565FFF;
    background-color: #fff;
    color: #000;
}

.guild-list {
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
    border: #565FFF 1px solid;
    border-radius: 5px;
}

.guild-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.guild-item.selected, .guild-item:hover {
    background-color: #565FFF;
}

.guild-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.image-container, .avatar-container {
    position: relative;
    margin-bottom: 20px;
}

.dashboard-bot-bot-banner {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.dashboard-bot-bot-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: #212234 4px solid;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transform: translate(30px, -50px);
    position: relative;
}

.image-upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 4px);
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: inherit;
    cursor: pointer;
}

.avatar-container .image-upload-overlay {
    width: 88px;
    height: 88px;
    border-radius: 50%;
}

.image-upload-overlay:hover {
    opacity: 1;
}

.image-upload-translate {
    transform: translate(30px, -50px);
}

.edit-icon {
    color: #ffffff;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 50%;
}

input.file-input {
    display: none;
}

.createBotButton {
    display: block;
    margin: 0 auto;
}