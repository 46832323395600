.logoutButton {
    max-height: 40px;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
}

.logoutText {
    color: #fff;
    margin-right: 10px;
}

.logoutIcon {
    max-width: 30px;
    border-radius: 50%;
}
