.command-container {
    max-width: 800px;
    margin: 40px auto; /* Added margin-top for more space from the top */
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #fff;
    background-color: #212234;
    border-radius: 5px;
    position: relative; /* Added for positioning the back button */
  }
  
  .command-title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .command-description {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .command-info {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .command-subheading {
    font-size: 1.5em;
    margin-top: 20px;
  }
  
  .command-list {
    margin-bottom: 15px;
    list-style-type: none;
    padding: 0;
  }
  
  .command-list li {
    margin-bottom: 10px;
  }
  
  .command-support {
    text-align: center;
    margin-top: 20px;
  }
  
  .back-button {
    position: absolute; /* Positioning the button */
    top: 20px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #565FFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
  }
  
  .back-button:hover {
    background-color: #3e44bd;
  }