.dashboardHeadbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #212234;
    border-radius: 10px;
    max-width: 80%;
    margin-top: 10%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}

.dashboardHeadbarText {
    display: flex;
    align-items: center;
}

.dashboardHeadline {
    font-size: 24px;
    margin-left: 20px;
    color: #fff ;
}

.dashboardSpanHeadline {
    font-weight: bold;
    color: #fff;
    margin-right: 5px;
}

.dashboardHeadbarLogo {
    max-width: 50px; /* Adjusted size */
    border-radius: 50%;
}

.dashboardInfo {
    text-decoration: none;
    color: #fff; /* Make text white */
    margin-left: 20px; /* Add spacing */
}

.dashboardInfo:hover {
    cursor: pointer;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Add cool effect */
}

.mobileHeadbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #212234;
    border-radius: 10px;
    margin: 10px;
}

.mobileHeadbarLogo {
    max-width: 30px; /* Adjusted size */
    border-radius: 50%;
}

.mobileHeadline {
    font-size: 18px;
    color: #fff;
    margin-left: 10px;
}

.logoutButton {
    max-height: 40px; /* Set max height */
}