.ayoCoreUserListContainer {
    overflow-y: auto;
    padding: 10px;
    border-radius: 8px;
    background-color: #212234;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 30px;
}

.ayoCoreHeader {
    text-align: center;
    color: white;
}

.ayoCoreSearchInput {
    width: calc(100% - 20px);
    padding: 8px;
    margin-bottom: 15px;
    border-radius: 4px;
    background-color: #2a2a3b; 
    color: #fff;
    border: 1px solid #363b98;
}

.ayoCoreUserList {
    list-style-type: none;
    padding: 0;
    max-height: 900px;
    overflow-y: auto;
    border: none;
}

.ayoCoreUserItem {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #2a2a3b;
    margin-bottom: 8px;
    border-radius: 4px;
}

@media (max-width: 600px) {
    .ayoCoreUserItem {
        width: fit-content;
    }
}

.ayoCoreCheckbox {
    margin-right: 16px;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #565FFF;
    border-radius: 4px; 
    background-color: transparent; 
    cursor: pointer;
    position: relative;
}

.ayoCoreCheckboxChecked {
    background-color: #565FFF;
}

.ayoCoreCheckboxChecked::after {
    content: '✔';
    color: #ADD8E6;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ayoCoreUserAvatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 16px;
}

.ayoCoreUserInfo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 16px;
}

.ayoCoreUserName {
    font-weight: bold;
    color: #fff;
}

.ayoCoreUserGlobalName {
    color: #aaa;
}

.ayoCoreUserId {
    color: #888;
}

.ayoCoreAddButton, .ayoCorePullButton, .ayoCoreDeleteButton {
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    background-color: #565FFF;
    color: white;
    cursor: pointer;
}

.ayoCoreAddButton:hover, .ayoCorePullButton:hover, .ayoCoreDeleteButton:hover {
    background-color: #4040FF;
}

.ayoCoreInfoButton, .ayoCorePullButton, .ayoCoreDeleteButton {
    padding: 10px 12px;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    margin-left: 8px;
}

.ayoCoreInfoButton:hover, .ayoCorePullButton:hover, .ayoCoreDeleteButton:hover {
    background-color: #4040FF;
}

.ayoCoreInfoButton {
    background-color: #565FFF;
}

.ayoCoreInfoButton:hover {
    background-color: #4040FF;
}

.ayoCorePullButton {
    background-color: #4CAF50;
}

.ayoCorePullButton:hover {
    background-color: #45A049;
}

.ayoCoreDeleteButton {
    background-color: #f44336;
}

.ayoCoreDeleteButton:hover {
    background-color: #e53935;
}

.ayoCoreRandomSelectButton {
    padding: 5px 8px;
    border: none;
    border-radius: 4px;
    background-color: #565FFF;
    color: white;
    cursor: pointer;
    height: 30px;
}

.ayoCoreRandomSelectButton:hover {
    background-color: #4040FF;
}

.ayoCoreSliderContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.ayoCoreSlider {
    -webkit-appearance: none;
    appearance: none;
    width: 75%;
    height: 8px;
    border-radius: 5px;
    background: linear-gradient(90deg, #4040FF 0%, #565FFF 100%);
    outline: none;
    opacity: 0.9;
    transition: opacity 0.2s;
    margin-right: 10px;
}

.ayoCoreSlider:hover {
    opacity: 1;
}

.ayoCoreSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.ayoCoreSlider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.ayoCoreSliderValue {
    color: white;
    background: linear-gradient(90deg, #4040FF 0%, #565FFF 100%);
    padding: 5px;
    border-radius: 4px;
    margin-right: 10px;
}

.ayoCoreControlContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
    background-color: #1e1e2b;
}

.ayoCoreAddButtonContainer {
    display: flex;
}

.ayoCoreAddButton {
    margin-right: 10px;
}

.ayoCoreEditButton {
    margin-left: auto;
}

.ayoCorePopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ayoCorePopupContent {
    background-color: #212234;
    padding: 15px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    color: #fff;
    text-align: center;
    position: relative;
}

.ayoCorePopupContentMoreWidth {
    width: 610px;
}

.ayoCorePopupAvatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 15px;
}

.ayoCoreClose {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
}

.ayoCoreCustomInput {
    width: 60px;
    margin-left: 10px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #565FFF;
    background-color: #fff;
    color: #000;
}

.ayoCoreCancelButton {
    padding: 5px 8px;
    border: none;
    border-radius: 4px;
    background-color: #f44336;
    color: white;
    cursor: pointer;
    margin-left: 8px;
}

.ayoCoreCancelButton:hover {
    background-color: #e53935;
}

.ayoCoreNoSelection {
    background-color: #4CAF50;
}

.ayoCoreNoSelection:hover {
    background-color: #45A049;
}

.ayoCoreSelection {
    background-color: #4CAF50; /* Green background for selection */
}

.ayoCoreSelection:hover {
    background-color: #45A049; /* Darker green on hover */
}

.ayoCoreFilterContainer {
    display: flex;
    justify-content: flex-start; /* Align filters to the start */
    gap: 10px;
    margin-bottom: 10px; /* Adjusted margin */
}

.ayoCoreFilterContainerCenter {
    justify-content: center; /* Center the filters */
}

.ayoCoreFilterSelect, .ayoCoreFilterDate {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #363b98;
    background-color: #2a2a3b;
    color: #fff;
}

.ayoCoreFilterButton {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #565FFF;
    color: white;
    cursor: pointer;
}

.ayoCoreFilterButton:hover {
    background-color: #4040FF;
}

.ayoCoreShake {
    animation: shake 0.3s;
    background-color: #f44336 !important;
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    20%, 60% { transform: translateX(-3px); }
    40%, 80% { transform: translateX(3px); }
}

.ayoCoreLabel {
    color: white;
    margin-top: 10px;
}

.ayoCoreInput {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #363b98;
    border-radius: 5px;
    background-color: #2a2a3b;
    color: #fff;
    box-sizing: border-box;
}

.ayoCoreError {
    color: red;
    margin-top: 5px;
}

.ayoCoreAliasInputContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.ayoCoreAliasPrefix {
    color: white;
    background-color: #2a2a3b;
    padding: 7px;
    transform: translateY(-2px);
    border: 1px solid #363b98;
    border-radius: 4px 0 0 4px;
}

.ayoCoreAliasInput {
    border-radius: 0 4px 4px 0;
    border-left: none;
}