.headbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #212234;
    border-bottom: none;
    color: white;
    max-width: 1040px; /* Slightly wider than the content */
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.backButton, .resetButton {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.5em;
}

.title {
    flex-grow: 1;
    text-align: center;
    font-size: 1.5em;
}
