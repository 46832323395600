.adminDashboard {
    display: flex;
}

.adminContent {
    flex-grow: 1;
    padding: 20px;
    transition: margin-left 0.3s ease-in-out;
}

.adminDashboard .adminContent {
    margin-left: 200px;
}

.adminDashboard .adminContent.sidebarClosed {
    margin-left: 50px;
}
