@import url('https://fonts.cdnfonts.com/css/gg-sans-2');

body {
  font-family: 'gg sans', sans-serif;
  background-color: #17171D;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #2c2f48;
}

body::-webkit-scrollbar-thumb {
  background-color: #565FFF;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #4343d3;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: white;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

button {
  background-color: #565FFF;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #4343d3;
}

.slider-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
}

.slider-input {
  display: none;
}

.slider {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 50px;
  transition: background-color 0.3s;
}

.slider::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.slider-input:checked + .slider {
  background-color: #565FFF;
}

.slider-input:checked + .slider::before {
  transform: translateX(20px);
}

/* 
  <label className="slider-label">
      <input
          type="checkbox"
          className="slider-input"
          checked={useTextArea}
          onChange={(e) => setUseTextArea(e.target.checked)}
      />
      <span className="slider"></span>
      Use Text Area
  </label>
*/

textArea {
  width: calc(100% - 20px); 
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #565FFF;
  border-radius: 4px;
  background-color: #2c2f48;
  color: white;
  resize: vertical;
  overflow: auto;
  white-space: pre;
  font-family: monospace;
  scrollbar-width: thin;
  scrollbar-color: #565FFF #2c2f48; 
}

textArea::-webkit-scrollbar {
  width: 8px; 
}

textArea::-webkit-scrollbar-track {
  background: #2c2f48; 
}

textArea::-webkit-scrollbar-thumb {
  background-color: #565FFF;
  border-radius: 4px;
}

textArea::-webkit-scrollbar-thumb:hover {
  background: #4343d3;
}

input[type="text"] {
  width: calc(100% - 20px); 
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #565FFF;
  border-radius: 4px;
  background-color: #2c2f48;
  color: white;
  font-family: monospace;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: start;
  align-items: center;
}