.row {
    display: flex;
    flex-direction: row;
}

.messageEditor {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #212234;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
    width: calc(100% - 43px);
    margin: 20px 0;
    border: 1px solid #565FFF;
}

.editorSection {
    width: 40%;
}

.previewSection {
    width: 50%;
    padding: 20px;
    background-color: #2c2f48;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
}

.textArea {
    width: calc(100% - 20px);
    height: 100px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #565FFF;
    resize: vertical;
    background-color: #2c2f48;
    color: white;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-left: auto;
    gap: 5px;
}

.buttonGroupbutton {
    width: 30px;
    height: 30px;
    padding: 0px;
}

.button:hover {
    background-color: #4343d3;
}

.saveButton {
    padding: 10px 20px;
    background-color: #565FFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    align-self: flex-end;
}

.saveButton:hover {
    background-color: #4343d3;
}

.closeButton {
    padding: 10px 20px;
    background-color: #ff5656;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    align-self: flex-end;
}

.closeButton:hover {
    background-color: #d34343;
}

.error {
    color: red;
    margin-top: 10px;
}

.embedEditor {
    margin-top: 20px;
}

.embedItem {
    background-color: #3b3f5c;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
}

.embedItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.embedItemContent {
    display: none;
    margin-top: 10px;
}

.embedItemContent.active {
    display: block;
}

.embedField {
    margin-bottom: 10px;
}

.embedField label {
    display: block;
    margin-bottom: 5px;
}

.embedField input,
.embedField textarea {
    width: calc(100% - 20px);
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #565FFF;
    background-color: #2c2f48;
    color: white;
}

.colorPicker {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #565FFF;
    background-color: #2c2f48;
    color: white;
}

.dropdown {
    margin-bottom: 10px;
}

.dropdown label {
    display: block;
    margin-bottom: 5px;
}

.dropdown select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.fieldItem {
    background-color: #3b3f5c;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
}

.fieldItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fieldItemContent {
    display: none;
    margin-top: 10px;
}

.fieldItemContent.active {
    display: block;
}

.dropdownItem {
    background-color: #3b3f5c;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
}

.dropdownItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdownItemContent {
    display: none;
    margin-top: 10px;
}

.dropdownItemContent.active {
    display: block;
}

.inlineField {
    display: flex;
    align-items: center;
    gap: 10px;
}

.inlineField input {
    flex: 1;
}

.embedPreview {
    border: 1px solid #565FFF;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: #2c2f48;
}

.embedPreview h4 {
    color: #00b0f4;
}

.embedPreview p {
    color: #ffffff;
}

.embedPreview img {
    max-width: 100%;
}

.embedPreview .thumbnail {
    max-width: 100px;
    float: right;
}

.embedPreview .field {
    display: block;
    width: 100%;
}

.embedPreview .field.inline {
    display: inline-block;
    width: 45%;
}

.embedPreview .author,
.embedPreview .footer {
    margin-top: 10px;
}

.embedPreview .footer img {
    max-width: 20px;
    vertical-align: middle;
}

.embedPreview .discordEmbed {
    background-color: #2f3136;
    border-left: 4px solid #00b0f4;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.embedPreview .discordEmbed h4 {
    color: #ffffff;
    margin: 0;
}

.embedPreview .discordEmbed p {
    color: #b9bbbe;
    margin: 5px 0;
}

.embedPreview .discordEmbed img {
    max-width: 100%;
    border-radius: 4px;
    margin-top: 10px;
}

.embedPreview .discordEmbed .thumbnail {
    max-width: 80px;
    float: right;
    margin-left: 10px;
}

.embedPreview .discordEmbed .field {
    display: block;
    width: 100%;
    margin-top: 5px;
}

.embedPreview .discordEmbed .field.inline {
    display: inline-block;
    width: 45%;
}

.embedPreview .discordEmbed .author,
.embedPreview .discordEmbed .footer {
    margin-top: 10px;
    color: #b9bbbe;
}

.embedPreview .discordEmbed .footer img {
    max-width: 20px;
    vertical-align: middle;
}

.discordMessage {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
}

.botAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.messageContent {
    background-color: #36393f;
    border-radius: 8px;
    padding: 10px;
    color: white;
    max-width: 600px;
}

.messageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.botName {
    font-weight: bold;
    color: #00b0f4;
}

.timestamp {
    font-size: 0.75rem;
    color: #72767d;
}

.messageText {
    margin-bottom: 10px;
}

.discordEmbed {
    background-color: #2f3136;
    border-left: 4px solid #00b0f4;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.embedTitle {
    color: #ffffff;
    margin: 0;
}

.embedDescription {
    color: #b9bbbe;
    margin: 5px 0;
}

.embedImage {
    max-width: 100%;
    border-radius: 4px;
    margin-top: 10px;
}

.embedThumbnail {
    max-width: 80px;
    float: right;
    margin-left: 10px;
}

.embedFields {
    margin-top: 10px;
}

.embedField,
.embedFieldInline {
    color: #b9bbbe;
    margin-top: 5px;
}

.embedFieldInline {
    display: inline-block;
    width: 45%;
}

.embedAuthor {
    margin-top: 10px;
    color: #b9bbbe;
    display: flex;
    align-items: center;
}

.authorIcon {
    max-width: 20px;
    vertical-align: middle;
    margin-right: 5px;
    border-radius: 50%;
}

.embedFooter {
    margin-top: 10px;
    color: #b9bbbe;
}

.footerIcon {
    max-width: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.footerTimestamp {
    margin-left: 5px;
    font-size: 0.75rem;
    color: #72767d;
}

.functionBoxContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 20px;
}

.functionBox {
    background-color: #3b3f5c;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 120px;
}

.functionBox:hover {
    background-color: #4343d3;
}

.functionBox h2 {
    margin: 0 0 10px 0;
    font-size: 1.2rem;
}

.functionBox p {
    margin: 0;
    font-size: 0.9rem;
    color: #b9bbbe;
}

.imagePreview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.previewImage {
    max-width: 100px;
    max-height: 100px;
    border-radius: 4px;
    object-fit: cover;
}

.imageEditor {
    margin-top: 20px;
}

.imageItem {
    background-color: #3b3f5c;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
}

.imageItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imageItemContent {
    display: none;
    margin-top: 10px;
}

.imageItemContent.active {
    display: block;
}

.selfRoleEditor {
    margin-top: 20px;
}

.selfRoleItem {
    background-color: #3b3f5c;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
}

.selfRoleItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selfRoleItemContent {
    display: none;
    margin-top: 10px;
}

.selfRoleItemContent.active {
    display: block;
}

.selfRolePreview {
    margin-top: 10px;
    color: #b9bbbe;
}

.linkButtonEditor {
    margin-top: 20px;
}

.linkButtonItem {
    background-color: #3b3f5c;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
}

.linkButtonItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.linkButtonItemContent {
    display: none;
    margin-top: 10px;
}

.linkButtonItemContent.active {
    display: block;
}

.linkButtonPreview {
    margin-top: 10px;
    color: #b9bbbe;
}

.embedItemContent.active,
.fieldItemContent.active,
.dropdownItemContent.active,
.imageItemContent.active,
.selfRoleItemContent.active,
.linkButtonItemContent.active {
    display: block;
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popupContent {
    background-color: #212234;
    border-radius: 10px;
    padding: 20px;
    max-width: 90%;
    width: 90%;
    height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
}

.trashButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
}

.trashButton:hover {
    background-color: transparent;
    color: #b0b0b0;
}

.emojiImage {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.role {
    color: #00b0f4;
    background-color: rgba(0, 176, 244, 0.1);
    padding: 2px 4px;
    border-radius: 3px;
    margin: 0 2px;
}

.channel {
    color: #7289da;
    background-color: rgba(114, 137, 218, 0.2);
    padding: 2px 4px;
    border-radius: 3px;
    margin: 0 2px;
    cursor: pointer;
}

.channel:hover {
    background-color: rgba(114, 137, 218, 0.4);
}

.voiceChannel {
    color: #7289da;
    background-color: rgba(114, 137, 218, 0.2);
    padding: 2px 4px;
    border-radius: 3px;
    margin: 0 2px;
    cursor: pointer;
}

.voiceChannel:hover {
    background-color: rgba(114, 137, 218, 0.4);
}
