/* Basiscontainer und allgemeine Einstellungen */
.botInfoContainer {
    background-color: #212234;
    max-width: 80%;
    border-radius: 10px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: #fff;
    position: relative;
    overflow: hidden;
}

.imageContainer, .avatarContainer {
    position: relative;
}

/* Banner-Einstellungen */
.dashboardBotBanner {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* Avatar-Einstellungen */
.dashboardBotAvatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: #212234 4px solid;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transform: translate(30px, -50px);
    position: relative; /* Wichtig für das Overlay */
}

/* Bot-Info-Header */
.botInfoHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translate(30px, -60px);
    width: calc(100% - 60px);
}

/* Bot-Info-Actions */
.botInfoActions {
    display: flex;
    gap: 10px;
}

.desktopActions {
    display: none;
}

.mobileActions {
    transform: translate(5px, -20px);
}

/* Media query for larger screens */
@media (min-width: 768px) {
    .desktopActions {
        display: flex;
    }
    .mobileActions {
        display: none;
    }
}

/* Overlay für Hover - spezifisch für Banner und Avatar */
.imageUploadOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 4px);
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: inherit;
    cursor: pointer;
}

/* Anpassung nur für das Avatar-Overlay */
.avatarContainer .imageUploadOverlay {
    width: 88px; /* Avatar-Breite */
    height: 88px; /* Avatar-Höhe */
    border-radius: 50%; /* Rundes Overlay für das Avatar */
}

.imageUploadOverlay:hover {
    opacity: 1;
}

.imageUploadTranslate {
    transform: translate(30px, -50px);
}

/* Stift-Icon */
.editIcon {
    color: #ffffff;
    font-size: 16px; /* Kleinere Größe für den Avatar */
    background-color: rgba(0, 0, 0, 0.6);
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 50%;
}

.fileInput {
    display: none;
}

.botNameChangeIcon:hover {
    color: #666;
    cursor: pointer;
}

.botPageRow {
    display: flex;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

/* Name-Popup-Overlay */
.namePopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.9);
}

/* Name-Popup-Content */
.namePopupContent {
    background-color: #212234;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    box-shadow: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Name-Popup-Header */
.namePopupContent h2 {
    color: #FFFFFF;
    margin-bottom: 20px;
}

/* Name-Input */
.nameInput {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #363b98;
    border-radius: 5px;
    background-color: #2a2a3b;
    color: #fff;
}

.dashboardBotId,
.dashboardBotExpirationDate {
    transform: translate(30px, -50px);
    margin-top: 0;
    margin-bottom: 0;
}

.dashboardBotName {
    margin-top: 0;
    margin-bottom: 0;
}