.header {
    background-color: #212234;
    border-radius: 15px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    position: sticky;
    top: 20px;
    z-index: 1000;
  }
  
  .header-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .logo {
    height: 50px;
    margin-right: 20px;
    border-radius: 50%;
  }
  
  .name {
    color: #fff;
    font-size: 24px;
  }
  
  .nav {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  .dashboard-button {
    background-color: #565FFF;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    margin-left: auto;
  }
  
  .dashboard-button:hover {
    background-color: #464ffc;
  }