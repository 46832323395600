.dashboardInfo {
    margin-left: 10px;
    font-size: 14px;
    color: #999;
}

.dashboardLogout {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5865F2;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    color: white;
    font-size: 1em;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
}

.logoutText {
    margin-right: 10px;
}

.logoutIcon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.dashboardLogout:hover {
    background-color: #4752C4;
}

.dashboardContainer {
    display: flex;
    justify-content: space-around;
    margin: 40px;
}

.dashboardContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 80%;
    margin: 0 auto;
}

.dashboardBotCard {
    flex: 1 1 calc(33.333% - 40px);
    max-width: calc(33.333% - 40px);
    margin: 10px;
    background-color: #212234;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .dashboardBotCard {
        flex: 1 1 100%;
        max-width: 100%;
    }
}

.dashboardBotContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.dashboardBotBanner {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.dashboardBotInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: #fff;
    height: 100%;
}

.dashboardBotInfoTop {
    display: flex;
    align-items: center;
    width: 100%;
}

.dashboardBotNameId {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.dashboardBotAvatar {
    max-width: 40px;
    border-radius: 50%;
}

.dashboardBotName {
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
}

.dashboardBotId {
    color: #999;
    margin-top: 0px;
    margin-bottom: 0px;
}

.dashboardBotDescription {
    margin-top: 10px;
    color: #999;
}

.dashboardBotInfoButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.dashboardBotButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    margin-top: auto;
}

.dashboardChooseButton {
    border: none;
    background-color: transparent;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.dashboardOptionsButton {
    border: none;
    background-color: transparent;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    justify-content: space-between;
}

.awaitingAssignment {
    /* Add any specific styles for awaiting assignment state if needed */
}

.spinnerContainer {
    /* Add styles for spinner container */
}

.spinner {
    /* Add styles for spinner */
}