.general-details {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.bot-details {
    margin: 20px auto;
    padding: 20px;
    background-color: #212234;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 16px;
    margin-left: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #565FFF;
}

input:checked + .slider:before {
    transform: translateX(12px);
}

.botPageContainer {
    padding: 10px;
}

.botPageRow {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

@media (min-width: 768px) {
    .bot-page-row {
        flex-direction: row;
    }
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #565FFF;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}