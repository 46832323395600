.notfound-container {
    text-align: center;
    padding: 50px;
    color: #fff;
    background-color: #212234;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.notfound-container h2 {
    font-size: 3em;
    margin-bottom: 20px;
}

.notfound-container p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.notfound-container a {
    color: #bbb;
    text-decoration: none;
}

.notfound-container a:hover {
    text-decoration: underline;
}
  