.adminSidebar {
    width: 200px;
    background-color: #212234;
    color: white;
    transition: width 0.3s ease-in-out;
    padding: 10px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.adminSidebar.closed {
    width: 50px;
}

.sidebarHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    overflow: hidden; /* Add this line to prevent overflow */
}

.logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.sidebarTitle {
    font-size: 1.2em;
    font-weight: bold;
}

.toggleButton {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    padding: 10px;
}

.toggleButton:hover {
    background-color: transparent;
}

.adminSidebar.closed .toggleButton {
    font-size: 1em;
}

.adminSidebar ul {
    list-style-type: none;
    padding: 0;
}

.adminSidebar li {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    overflow: hidden; /* Add this line to prevent text overflow */
}

.adminSidebar li:hover {
    background-color: #2563eb;
}

.icon {
    margin-right: 10px;
    color: #93c5fd;
}

.arrowIcon {
    margin-left: auto;
    color: #93c5fd;
}

.subMenu {
    list-style-type: none;
    padding-left: 20px;
    background-color: 2px solid #93c5fd; /* Add this line to visually connect the dropdown items */
}

.subMenu li {
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.subMenu li:hover {
    background-color: #2563eb;
}
