.container {
    padding: 20px;
    background-color: #212234;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
    max-width: 1000px;
    margin: 20px auto;
    border: 1px solid #565FFF;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.editButton {
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #565FFF;
    margin-bottom: 20px;
    margin-top: 20px;
}

.editButton:hover {
    background-color: #4343d3;
}

.saveButton {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.saveButton:hover {
    background-color: #218838;
}

.resetButton {
    padding: 10px 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.resetButton:hover {
    background-color: #c82333;
}

.sliderContainer {
    margin-bottom: 20px;
}

.selectContainer {
    margin-bottom: 20px;
    width: 100%;
}

.selectLabel {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #ccc;
}