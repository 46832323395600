.registerPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 90%;
    max-width: 600px;
    margin: auto;
    border-radius: 10px;
    color: white;
    background-color: #212234;
    margin-top: 10%;
}

@media (max-width: 768px) {
    .registerPage {
        position: relative;
        margin-top: 50%;
    }
}

.registerPage img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.registerPage .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.registerPage .profileName {
    margin-top: 20px;
    text-align: center;
}

.registerPage button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #7289da;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.registerPage button:hover {
    background-color: #5b6eae;
}