.botContent {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 0;
}

.searchInput {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
}

.filterContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.filterSelect {
    margin-left: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
}

.botContainer {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 600px;
    scrollbar-width: none;
    margin-left: 60px;
}

.botContainer::-webkit-scrollbar {
    display: none;
}

.botCard {
    background-color: #212234;
    color: white;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 18%;
    box-sizing: border-box;
}

.botAvatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.botStatus {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.online {
    background-color: #10b981;
}

.offline {
    background-color: #ef4444;
}

.idle {
    background-color: #f59e0b;
}

.dnd {
    background-color: #000000;
}

.botName {
    font-size: 1.2em;
    text-align: center;
}

.botPopup {
    background: #212234;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupContent {
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-height: 80%;
    overflow-y: auto;
}

.closeButton {
    background-color: #ef4444;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 10px;
}
