.messageContainer {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 300px;
    text-align: center;
    opacity: 0;
    animation: fadeIn 0.5s forwards, fadeOut 0.5s forwards 4.5s;
}

.messageSuccess {
    background-color: green;
}

.messageError {
    background-color: red;
}

.messageWarning {
    background-color: orange;
}

.messageInfo {
    background-color: blue;
}

.messageNone {
    background-color: gray;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
