.container {
    padding: 20px;
    background-color: #212234;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
    max-width: 600px;
    margin: 20px auto;
    border: 1px solid #565FFF;
}

.buttonGroup {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.button {
    padding: 10px 20px;
    background-color: #565FFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #4343d3;
}
