.container {
    padding: 20px;
    background-color: #212234;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
    max-width: 1000px;
    margin: 20px auto;
    border: 1px solid #565FFF;
}

.section {
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.textBox {
    padding: 10px;
    border: 1px solid #565FFF;
    border-radius: 4px;
    background-color: #2c2f48;
    color: white;
    width: calc(100% - 20px);
}

.button {
    padding: 10px 15px;
    background-color: #565FFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #4343d3;
}
