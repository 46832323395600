.savebar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #212234;
    border-top: none;
    color: white;
    max-width: 1040px; /* Slightly wider than the content */
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: sticky;
    bottom: 20px; /* A bit above the bottom of the screen */
}

.icon {
    font-size: 1.5em;
    margin-right: 10px;
}

.buttonContainer {
    display: flex;
    gap: 10px;
}

.saveButton, .softResetButton {
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.saveButton {
    background-color: #28a745;
}

.saveButton:hover {
    background-color: #218838;
}

.softResetButton {
    background-color: #ffc107;
}

.softResetButton:hover {
    background-color: #e0a800;
}
