.dashboardBotConfigsTop {
    display: flex;
    flex-direction: row;
    background-color: #212234;
    margin-bottom: 30px;
    max-width: 80%;
    border-radius: 10px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: #fff;
    position: relative;
}

.dashboardBotConfigHeader {
    margin-left: auto;
    margin-right: auto;
    color: #fff;
}

.dashboardBotConfigSearch {
    font-size: 20px;
    margin-right: 10px;
    margin-top: 30px;
}

.dashboardBotConfigSearch:hover {
    cursor: pointer;
}

.dashboardBotConfigCards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-height: 400px;
    overflow-y: auto;
    justify-content: center;
} 

.dashboardBotConfigCard {
    background-color: #212234;
    border: 1px solid #212234;
    border-radius: 8px;
    padding: 10px;
    width: calc(100% - 20px);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}

.dashboardBotConfigCard:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboardBotConfigCard h2 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
    flex: 1;
    cursor: pointer;
}

.dashboardBotConfigSearchBox {
    background-color: #2a2a3b;
    border: 1px solid #363b98;
    border-radius: 4px;
    color: #fff;
    right: 45px;
    margin-bottom: 15px;
    padding: 8px;
    position: absolute;
    top: 25px;
    width: 30%;
}

@media (min-width: 768px) {
    .dashboardBotConfigCard {
        width: calc(25% - 10px);
    }
    .dashboardBotConfigCards {
        max-height: none;
    }
}