.cardsSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 20px auto;
    perspective: 1000px;
    max-width: 80%; /* Begrenzte Breite, um maximal 3 Karten in einer Zeile anzuzeigen */
}

.card {
    flex: 1 1 calc(33.33% - 20px); /* Drei Karten pro Zeile, mit Abstand */
    max-width: calc(33.33% - 20px); /* Maximale Breite für jede Karte */
    height: 300px;
    perspective: 1000px;
}

.card:hover {
    z-index: 1000;
}

.cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.card:hover .cardInner {
    transform: rotateY(180deg);
}

.cardFront,
.cardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cardFront {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #212234;
}

.cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.cardBack {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    background-color: #212234;
    color: #fff;
    transform: rotateY(180deg);
    border-radius: 10px;
}
