.login-container {
    background-color: #212234;
    width: 90%;
    max-width: 500px;
    height: auto;
    border-radius: 25px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-close-button {
    color: #999;
    margin-left: 85%;
    transform: translateY(15px);
}

.login-close-button:hover {
    color: #fff;
    cursor: pointer;
}

.login-top {
    display: flex;
    flex-direction: row;
}

.login-logo {
    height: 50px;
    border-radius: 50%;
    margin: 10px;
}

.login-headline {
    color: #fff;
}

.login-span-headline {
    margin-right: 5px;
    font-weight: bold;
}

.login-button {
    background-color: #565FFF;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px;
}

.login-disclaimer {
    color: #999;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    width: 100%;
    max-width: 350px;
}

.login-disclaimer-bottom {
    margin-top: 10px;
}

.login-link {
    color: #565FFF;
}