.actionLog {
    height: 400px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 20px;
    background-color: #212234;
    color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    overflow-y: hidden;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .actionLog {
        height: 400px;
        margin-bottom: 0px;
    }
}

.actionLog h2 {
    text-align: center;
}

.filters {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column; 
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}

@media (min-width: 768px) {
    .filters {
        flex-direction: row; /* Original horizontal layout for larger screens */
    }
    .filters select {
        width: calc(33% - 10px); /* Original width for larger screens */
    }
}

.filters select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #363b98;
    background-color: #2a2a3b;
    color: #FFFFFF;
    width: 80%; /* Make filters wider in vertical layout */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M7.247 11.14l-4.796-5.481C1.451 5.253 1.675 4.5 2.404 4.5h11.192c.73 0 .953.753.553 1.159l-4.796 5.481a1 1 0 0 1-1.512 0z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px 16px;
}

.filters select:hover {
    background-color: #363b98;
}

.logList {
    max-height: 300px;
    overflow-y: auto;
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popupContent {
    background-color: #212234;
    border-radius: 10px;
    padding: 20px;
    max-width: 700px;
    width: 90%;
    height: 70%;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
}

.popupContent h2 {
    color: #FFFFFF;
    margin-bottom: 20px;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #FFFFFF;
    background-color: #565FFF;
    font-size: 20px;
    cursor: pointer;
}

.logList {
    max-height: calc(100% - 150px);
    overflow-y: auto;
    position: relative;
}

.logList::after {
    content: "Click for full view";
    position: absolute;
    bottom: 50%;
    right: 50%;
    background-color: rgba(0, 0, 0, 0.7);
    color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}

.logList:hover::after {
    opacity: 1;
}

.logList::-webkit-scrollbar,
.popupContent::-webkit-scrollbar {
    width: 8px;
}

.logList::-webkit-scrollbar-thumb,
.popupContent::-webkit-scrollbar-thumb {
    background: #565FFF;
    border-radius: 4px;
}

.logList::-webkit-scrollbar-track,
.popupContent::-webkit-scrollbar-track {
    background: #212234;
}