.permissionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.owners, .admins {
    width: 80%;
    margin-bottom: 20px;
}

.addAdminContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.addAdminButton {
    padding: 10px;
    border: none;
    border-radius: 50%;
    background-color: #565FFF;
    color: white;
    cursor: pointer;
    font-size: 20px;
    margin-top: 10px;
}

.addAdmin {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.addAdmin input {
    margin-right: 10px;
    background-color: #2a2a3b;
    color: #fff;
    border: 1px solid #565FFF;
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.permissionPopupContent {
    background-color: #212234;
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
    width: 20%;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    text-align: center;
}

.permissionPopupContent h2, .permissionPopupContent h3 {
    color: #FFFFFF;
}

.permissionPopupContent ul {
    list-style: none;
    padding: 0;
}

.permissionPopupContent li {
    color: #FFFFFF;
    margin-bottom: 5px;
}

.permissionPopupContent input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #565FFF;
    border-radius: 5px;
    background-color: #2a2a3b;
    color: #fff;
}

.permissionPopupContent button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #565FFF;
    color: #FFFFFF;
    cursor: pointer;
}

.removeButton {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #FF5656;
    color: #FFFFFF;
    cursor: pointer;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #FFFFFF;
    background-color: #565FFF;
    font-size: 20px;
    cursor: pointer;
}