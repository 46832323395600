.container {
    padding: 20px;
    background-color: #212234;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
    max-width: 1000px;
    margin: 20px auto;
    border: 1px solid #565FFF;
}

.button {
    margin: 5px;
    padding: 10px 15px;
    background-color: #565FFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #4343d3;
}

.fileUpload {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border: 2px dashed #565FFF;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    background-color: #2c2f48;
}

.fileUpload p {
    margin: 0;
    color: #ccc;
}

.fileInput {
    display: none;
}

.searchBar {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #565FFF;
    border-radius: 5px;
    background-color: #2c2f48;
    color: white;
}

.searchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.addButton {
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transform: translate(10px, -10px);
}

.addButton:hover {
    background-color: #218838;
}

.moduleList {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
    border: 1px solid #565FFF;
    border-radius: 4px;
    padding: 10px;
    background-color: #2c2f48;
    scrollbar-width: none; /* Firefox */
}

.moduleList::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.moduleItem {
    padding: 10px;
    border: 1px solid #565FFF;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3b3f5c;
}

.moduleItem:hover {
    background-color: #4a4f6e;
}

.versionControls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.versionControl {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.versionNumber {
    margin-left: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.submitButton {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 20px auto 0 auto;
}

.submitButton:hover {
    background-color: #218838;
}

.textBox {
    padding: 10px;
    margin: 5px;
    border: 1px solid #565FFF;
    border-radius: 4px;
    background-color: #2c2f48;
    color: white;
    width: calc(100% - 20px); /* Full width minus padding */
}

.formRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #565FFF;
    padding-bottom: 10px;
}

.versionDisplay {
    text-align: center;
    margin-bottom: 20px;
    color: #FFFFFF;
}

.dropdown {
    padding: 10px;
    margin: 5px;
    border: 1px solid #565FFF;
    border-radius: 4px;
    background-color: #2c2f48;
    color: white;
}