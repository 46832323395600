.serverDisplay {
    width: 30vb;
    height: 400px;
    margin: 20px auto;
    background-color: #212234;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.serverDisplay h2, .serverDisplay h3 {
    color: #FFF;
    text-align: center;
}

.serverDisplay p {
    color: #FFF;
    text-align: center;
}

.guildDisplay {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
}

.guildIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    margin: 0 auto 10px auto;
    border: none;
}

.serverDisplay button {
    display: block;
    margin: 0 auto;
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popupContent {
    background-color: #212234;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
}

.popupContent h2 {
    color: #FFFFFF;
    margin-bottom: 20px;
}

.searchBar {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #565FFF;
    border-radius: 5px;
    background-color: #FFFFFF;
}

.guildList {
    max-height: 500px;
    overflow-y: auto;
}

.guildItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #565FFF;
}

.guildItem span {
    color: #FFFFFF;
}

.guildItem:last-child {
    border-bottom: none;
}

.disclaimer {
    margin-top: 10px;
    font-size: 12px;
    color: #999999;
}

.disclaimerIcon {
    font-weight: bold;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #FFFFFF;
    background-color: #565FFF;
    font-size: 20px;
    cursor: pointer;
}

.guildList::-webkit-scrollbar {
    width: 8px;
}

.guildList::-webkit-scrollbar-thumb {
    background: #565FFF;
    border-radius: 4px;
}

.guildList::-webkit-scrollbar-track {
    background: #212234;
}
