.container {
    padding: 20px;
    background-color: #212234;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
    max-width: 1000px;
    margin: 20px auto;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
    background-color: #2a2a3b;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
    transition: background-color 0.3s, border-color 0.3s;
}

.buttonContainer:hover {
    background-color: #1e1e2e;
}

.editButton {
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #565FFF;
    margin-bottom: 20px;
    margin-top: 20px;
}

.editButton:hover {
    background-color: #4343d3;
}

.saveButton {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.saveButton:hover {
    background-color: #218838;
}

.resetButton {
    padding: 10px 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.resetButton:hover {
    background-color: #c82333;
}

.sliderContainer {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2a2a3b;
    padding: 10px;
    border-radius: 5px;
}

.selectContainer {
    margin-bottom: 20px;
    width: 100%;
    background-color: #2a2a3b;
    padding: 10px;
    border-radius: 5px;
}

.selectLabel {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #ccc;
}

.inputContainer {
    margin-bottom: 20px;
    background-color: #2a2a3b;
    padding: 10px;
    border-radius: 5px;
}

.inputLabel {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #ccc;
}

.row {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #565FFF;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.optionToggle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    border-bottom: 2px solid #565FFF;
    background-color: #2a2a3b;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.optionButton {
    width: 50%;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    background-color: #3a3a5c;
    margin: 0;
    transition: background-color 0.3s;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.optionButton:hover {
    background-color: #4a4a6c; 
    outline: 2px solid #4a4a6c;
}

.optionButton.active {
    background-color: #4a4a6c;
    outline: 2px solid #4a4a6c;
}

.sendButton {
    margin-top: 10px;
}

.inputContainer input[type="text"] {
    margin-top: 10px;
}

.slider-label input[type="checkbox"] {
    border: none;
}
