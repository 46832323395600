.container {
    position: relative;
    display: inline-block;
}

.button {
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: #565FFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #4343d3;
}

.dropdown {
    position: absolute;
    top: 35px;
    left: 0;
    background-color: #2c2f48;
    border: 1px solid #565FFF;
    border-radius: 4px;
    width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.searchBar {
    width: calc(100% - 20px);
    padding: 8px;
    margin: 10px;
    border-radius: 4px;
    border: 1px solid #565FFF;
    background-color: #212234;
    color: white;
}

.searchBar::placeholder {
    color: #b9bbbe;
}

.options {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
}

.option {
    padding: 5px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #3b3f5c;
}

.option:hover {
    background-color: #4343d3;
}

.emojiImage {
    max-width: 100%;
    max-height: 100%;
}

/* Custom scrollbar styles */
.dropdown::-webkit-scrollbar {
    width: 8px;
}

.dropdown::-webkit-scrollbar-track {
    background: #2c2f48;
}

.dropdown::-webkit-scrollbar-thumb {
    background-color: #565FFF;
    border-radius: 4px;
}

.dropdown::-webkit-scrollbar-thumb:hover {
    background-color: #4343d3;
}
