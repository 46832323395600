.button {
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: #565FFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #4343d3;
}
